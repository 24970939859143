/** @jsxImportSource @emotion/react */
import React from 'react'
import {Columns, divCSS, DivI, Rows} from "@nic-react-ui/core";
import {FIELDS_GAP, PAPER_PADDING, PAPER_TITLE_GAP} from "../../configurations/ui-constants";

import {ReloadIcon} from "../../icons";
import {useNavigate} from "react-router-dom";
import {UIComponentConf, useComponentConfiguration} from "../../layout/LayoutManager";

export type RoleType = 'normal' | 'lower' | 'lowest' | 'higher' | 'highest' | 'none'
interface TitledPaperI extends DivI {
    asCard: boolean
    backgroundColor?: string
    title?: string | React.ReactElement
    icon?: React.ReactElement
    border?: string
    borderRadius?: string
    fieldsGap?: string
    role?: RoleType,
    showReload?: boolean
}

const ReloadAction: React.FC<{}> = () => {
    const navigate = useNavigate()
    return (
        <ReloadIcon cursor={'pointer'} onClick={() => {navigate(0)}}/>
    )
}

export const TitledPaper: React.FC<TitledPaperI> = (props) => {
    const role = props.role ?? 'normal'
    const uiConf = useComponentConfiguration()
    const titlePaperConf: UIComponentConf = uiConf['titledPaper'] as UIComponentConf
    const surfaceConf: React.CSSProperties = titlePaperConf[role] as React.CSSProperties
    const typographyConf: UIComponentConf = uiConf['typography'] as UIComponentConf
    const titleConf: React.CSSProperties = typographyConf['title'] as React.CSSProperties
    const defaultCss = {
        height: 'fit-content',
        marginBottom:`${props.marginBottom??'20px'}`,
        border:`${props.border ?? 'none'}`,
        borderRadius:`${props.borderRadius ?? 'none'}`,
        padding:`${props.padding ?? PAPER_PADDING}`,
        asCard:`${props.asCard}`,
        backgroundColor:`${props.backgroundColor}`,
        color: `${props.color}`,
        width: `100%`,
    }
    const reload = props.showReload ?? false
    return (
        <div
            css={[ divCSS(defaultCss), {...surfaceConf}, divCSS(props) ]}
        >
            <Rows alignItems={'flex-start'} justifyContent={'flex-start'} gap={PAPER_TITLE_GAP}>
                {props.title &&
                    <Columns width={'100%'} gap={PAPER_TITLE_GAP} alignItems={'center'} justifyContent={'space-between'}>

                        {props.title && <div css={{...titleConf}}>{props.title}</div>}
                        <Columns gap={'5px'} justifyContent={'center'}>
                            {props.icon && props.icon}
                            {reload && <span>|</span>}
                            {reload && <ReloadAction/>}
                        </Columns>
                    </Columns>
                }
                <Rows alignItems={'flex-start'} gap={props.fieldsGap ?? FIELDS_GAP}>
                    {props.children}
                </Rows>
            </Rows>
        </div>
    )
}
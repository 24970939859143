import {LayoutBuilderElement} from "@nic-react/layout";

export const defaultRainLayout: LayoutBuilderElement = {
    element: 'vertical',
    options: {  minHeight: '100vh',  justifyContent: 'space-between'},
    elements: [
        {
            element: 'vertical',
            options: {  justifyContent: 'flex-start', alignItems: 'flex-start'},
            elements: [
                {
                    element: 'vertical',
                    options: { gap: '0' },
                    elements: [
                        {
                            element: 'header',
                            options: {
                                backgroundColor: 'white',
                                alignItems: 'flex-start',
                                color: 'black',
                            },
                        }
                    ],
                },
                {
                    element: 'horizontal',
                    options: { display: 'flex',  width: '100%',  alignSelf: 'stretch',  justifyContent: 'flex-start'},
                    elements: [
                        {
                            element: 'body',
                            options: { display: 'flex',  width: '95%',  alignSelf: 'stretch', marginLeft: '20px'},
                        },
                        {
                            element: 'rightSide',
                            options: { display: 'flex',  marginRight: '20px', width: '15%',  justifyContent: 'flex-start', alignSelf: 'stretch'},
                        },
                    ]
                },

            ],
        },

        {
            element: 'footer',
            options: {
                width: '100%',
                display: 'flex',
                backgroundColor: 'white',
                color: 'black',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    ],
}

export const default2RainLayout: LayoutBuilderElement = {
    element: 'horizontal',
    elements: [
        {
           element: 'leftSide',
        },
        {
            element: 'vertical',
            elements: [
                {
                    element: 'header'
                },
                {
                    element: 'body'
                },
                {
                    element: 'footer'
                }
            ]
        },

    ],
}


export const default3RainLayout: LayoutBuilderElement = {
    element: 'vertical',
    elements: [
        {
            element: 'header',
        },
        {
            element: 'horizontal',
            options: {  justifyContent: 'space-between', alignItems: 'flex-start', width:'100%', marginLeft: '20px', marginRight: '20px', gap: '10px'},
            elements: [
                {
                    options: {width: 'inherit-parent'},
                    element: 'leftSide'
                },
                {
                    options: {  width: '100%', marginRight: '20px'},
                    element: 'body'
                },

            ]
        },
        {
            element: 'footer'
        }
    ],
}



export const defaultMiniLayout: LayoutBuilderElement = {
    element: 'vertical',
    options: {  minHeight: '100vh',  justifyContent: 'space-between'},
    elements: [
        {
            element: 'vertical',
            options: {  justifyContent: 'flex-start', alignItems: 'flex-start'},
            elements: [
                {
                    element: 'vertical',
                    options: { gap: '10px' },
                    elements: [
                        {
                            element: 'header',
                            options: {
                                alignItems: 'flex-start',
                            },

                        },
                        {
                            options: {width: 'inherit-parent'},
                            element: 'leftSide'
                        },
                    ],
                },
                {
                    element: 'body',
                    options: { display: 'flex',  width: '98%',  justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch'},
                },

            ],
        },
        {
            element: 'footer',
        },
    ],
}
export const defaultLayout: Record<string, LayoutBuilderElement> = {
    'xxl': default3RainLayout,
    'xl': default3RainLayout,
    'l': defaultMiniLayout,
    'm': defaultMiniLayout,
    's': defaultMiniLayout,
    'xs': defaultMiniLayout,
    'sm': defaultMiniLayout
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

interface ToggleOnIconI extends IconI {}

export const ToggleOnIcon: React.FC<ToggleOnIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(16, 16,  props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 16 16"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path d="M5 3a5 5 0 000 10h6a5 5 0 000-10H5zm6 9a4 4 0 110-8 4 4 0 010 8z" />
            </svg>
        </div>
    )
}

/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";

export const CloseIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg fill="none" viewBox="0 0 24 24" height={ratioX} width={ratioY}>
                <path
                    fill="currentColor"
                    d="M16.34 9.322a1 1 0 10-1.364-1.463l-2.926 2.728L9.322 7.66A1 1 0 007.86 9.024l2.728 2.926-2.927 2.728a1 1 0 101.364 1.462l2.926-2.727 2.728 2.926a1 1 0 101.462-1.363l-2.727-2.926 2.926-2.728z"
                />
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 110-18 9 9 0 010 18z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    )
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";

interface ChevronDownI extends IconI {}

export const ChevronDown: React.FC<ChevronDownI> = (props) => {
    const [ratioX, ratioY] = ratioXY(16,16, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                height={ratioY}
                width={ratioX}
            >
                <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                />
            </svg>
        </div>
    )
}
import {__DEV__} from "./index";
import {AppConf} from "./AppConf";

export enum LogLevel {
    info,
    warning,
    error
}

export const log = (level: LogLevel, message: any, component?: string) => {
    if (__DEV__) {
        const packageName = component ?? AppConf.getApplication();
        switch (level) {
            case LogLevel.warning:
                console.warn(`${packageName} `, message);
                break;
            case LogLevel.error:
                console.error(`[${packageName}] `, message);
                break;
            default:
                console.log(`[${packageName}] `, message);
        }
    }
}
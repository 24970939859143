/** @jsxImportSource @emotion/react */
import React from "react";
import {Div} from "@nic-react-ui/core";
import {Loader} from "./Loader";
import {useTranslation} from "@nic-react-i18n/core";
import './splash.css'

interface SplashI {
    message?: string
}

export const Splash: React.FC<SplashI> = (props) => {
    const {t} = useTranslation();
    return (
        <div
            className={'splash'}
            css={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Loader ratio={250}/>
            <Div fontSize={'1.2rem'}>{props.message ?? t('splash.splashTitle')}</Div>
        </div>
    )
}

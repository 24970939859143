/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

interface ToggleOffIconI extends IconI {}

export const ToggleOffIcon: React.FC<ToggleOffIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(16, 16,  props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 16 16"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path d="M11 4a4 4 0 010 8H8a4.992 4.992 0 002-4 4.992 4.992 0 00-2-4h3zm-6 8a4 4 0 110-8 4 4 0 010 8zM0 8a5 5 0 005 5h6a5 5 0 000-10H5a5 5 0 00-5 5z" />
            </svg>
        </div>
    )
}

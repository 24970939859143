import Keycloak from "keycloak-js";

export const headerFromToken = (token?: string) => {
    if (token) {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    } else {
        return {}
    }
};
const refreshToken = async (keycloak: Keycloak) => {

    return new Promise<Keycloak>((resolve, reject) => {

        keycloak.updateToken(5).then(function () {
            resolve(keycloak)
        }).catch(function () {
            reject(keycloak)
        });
    });
};


export const authFetch = async (kc: Keycloak, callback: (kc: Keycloak, args?: any)=>Promise<any>,
                                args?: any) => {

    try {
        const kcU: Keycloak = await refreshToken(kc);
        return callback(kcU, args);
    } catch (e) {
        console.log("Error");
        kc.login();
    }
};
import React from 'react'
import {AppConf} from "../../../AppConf";
import {Button} from "../../Button";
import {TitledPaper} from "../../layout/TitledPaper";
import {Div, Flex, Rows} from "@nic-react-ui/core";
import {useTranslation} from "@nic-react-i18n/core";
import {Election} from "../../../api/electionSchemas";

interface RealmDashboardI {
    election?: Election
}

export const RealmDashboard: React.FC<RealmDashboardI> = () => {
    const {t} = useTranslation()

    return (

        <TitledPaper role={'highest'} asCard={false}>

            <Rows gap={'30px'}>
                <Div fontWeight={'700'} fontSize={'3rem'}>{t('elections.profile.choose')}</Div>

                <Flex direction={'row'}  width={'100%'} gap={'20px'} justifyContent={'center'}>
                    <Button
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={() => {
                            const redirect = AppConf.getRealmConf('registrar').redirect
                            window.location.replace(redirect)
                        }}
                    >
                        {t('elections.profile.registrar')}
                    </Button>

                    <Button
                        onClick={() => {
                            const redirect = AppConf.getRealmConf('elections').redirect
                            window.location.replace(redirect)
                        }}
                    >
                        {t('elections.profile.candidate')}
                    </Button>
                </Flex>
            </Rows>


        </TitledPaper>

    )
}
import React from 'react'
import _ from 'lodash';

export interface ApplicationStoreI {
    store: Record<string, any>
    read: (key: string) => any
    write: (key: string, value: any) => void
}


export const ApplicationContext = React.createContext<ApplicationStoreI>({
    store: {},
    read: (key: string) => {},
    write: (key: string, value: any) => {}
})

export const ApplicationStore: React.FC<React.PropsWithChildren> = (props) => {
    const [appStore, setAppStore] = React.useState<any>({})
    const getHandler = (key: string): object => {
        return _.get(appStore, key);
    }
    const setHandler = (key: string, value: any) => {
        // Forse non posso lavorare direttamente perchè read_only
        setAppStore( (store: any) => _.set(_.cloneDeep(store), key, value));
    }
    return (
        <ApplicationContext.Provider value={{
            store: appStore,
            read: getHandler,
            write: setHandler.bind(appStore)
        }}>
            {props.children}
        </ApplicationContext.Provider>
    )
}

export const useAppContext = () => {
    const appCtx = React.useContext(ApplicationContext);
    return appCtx
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";
interface MenuIconI extends IconI {}

export const MenuIcon: React.FC<MenuIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24,24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
            </svg>
        </div>
    )
}
import React from 'react'
import {Columns, DivI, Flex, FlexI, mediaMatch, Rows} from '@nic-react-ui/core'

export type MediaT = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'sm'
export type DirectionT =  'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined
export interface LayoutBuilderElement {
    element?:
        | 'header'
        | 'footer'
        | 'body'
        | 'leftSide'
        | 'rightSide'
        | 'breadcrumb'
        | 'help'
        | 'navigation'
        | 'subNavigation'
        | 'horizontal'
        | 'vertical'
    options?: DivI & FlexI
    elements?: Array<LayoutBuilderElement>
}

type LayoutElement =
    | 'HEADER'
    | 'FOOTER'
    | 'LEFT-SIDE'
    | 'RIGHT-SIDE'
    | 'BODY'
    | 'BREADCRUMB'
    | 'NAVIGATION'
    | 'SUB-NAVIGATION'
    | 'HELP'

interface LayoutContextI {
    componentConfiguration: UIComponentConf
    media: MediaT
    header: React.ReactElement
    breadcrumb?: React.ReactElement
    body: React.ReactElement
    footer?: React.ReactElement
    leftSide?: React.ReactElement
    rightSide?: React.ReactElement
    navigation?: React.ReactElement
    subNavigation?: React.ReactElement
    help?: React.ReactElement
    resetLayouts: (elements: LayoutElement[]) => void
    setLayoutElement: (element: LayoutElement, component: React.ReactElement) => void
    setLayoutElements: (settings: Record<LayoutElement, React.ReactElement>) => void
}

interface LayoutManagerI extends React.PropsWithChildren {
    header: React.ReactElement
    breadcrumb?: React.ReactElement
    body: React.ReactElement
    footer?: React.ReactElement
    leftSide?: React.ReactElement
    rightSide?: React.ReactElement
    navigation?: React.ReactElement
    subNavigation?: React.ReactElement
    help?: React.ReactElement
    layoutConf?: Record<string, LayoutBuilderElement>
    componentConfiguration: UIComponentConf
}

export type UIComponentConf = {[key: string]: UIComponentConf | React.CSSProperties | string}

export const LayoutContext = React.createContext<LayoutContextI>({
    componentConfiguration: {},
    media: "xxl",
    header: <></>,
    breadcrumb: <></>,
    footer: <></>,
    body: <></>,
    leftSide: <></>,
    rightSide: <></>,
    navigation: <></>,
    subNavigation: <></>,
    help: <></>,
    // tslint:disable-next-line
    resetLayouts: (elements: LayoutElement[]) => {},
    // tslint:disable-next-line
    setLayoutElement: (element: LayoutElement, component: React.ReactElement) => {},
    // tslint:disable-next-line
    setLayoutElements: (settings: Record<LayoutElement, React.ReactElement>) => {},
})

export const LayoutManager: React.FC<LayoutManagerI> = ({

                                                            header,
                                                            breadcrumb,
                                                            body,
                                                            footer,
                                                            leftSide,
                                                            rightSide,
                                                            help,
                                                            navigation,
                                                            subNavigation,
                                                            layoutConf,
                                                            componentConfiguration
                                                        }: React.PropsWithChildren<LayoutManagerI>) => {
    const [headerComp, setHeaderComp] = React.useState<React.ReactElement>(header)
    const [breadcrumbComp, setBreadcrumbComp] = React.useState<React.ReactElement | undefined>(
        breadcrumb
    )
    const [footerComp, setFooterComp] = React.useState<React.ReactElement | undefined>(footer)
    const [leftSideComp, setLeftSideComp] = React.useState<React.ReactElement | undefined>(
        leftSide
    )
    const [rightSideComp, setRightSideComp] = React.useState<React.ReactElement | undefined>(
        rightSide
    )
    const [bodyComp, setBodyComp] = React.useState<React.ReactElement>(body)
    const [helpComp, setHelpComp] = React.useState<React.ReactElement | undefined>(help)
    const [navigationComp, setNavigationComp] = React.useState<React.ReactElement | undefined>(
        navigation
    )
    const [subNavigationComp, setSubNavigationComp] = React.useState<
        React.ReactElement | undefined
        >(subNavigation)

    const [fullLayout, setFullLayout] = React.useState<React.ReactElement>(<></>)

    const findMQuery = (): MediaT => {
        const queries = Object.keys(mediaMatch);
        let mediaM = undefined;
        let queryTag = "";
        for (let q = 0; q < queries.length; q++) {
            queryTag = queries[q];
            const mM = mediaMatch[queryTag];
            mediaM = window.matchMedia(mM.replace("@media", ""));
            if (mediaM.matches) {
                break;
            }
        }
        return queryTag as MediaT
    }
    const [mediaQuery, setMediaQuery] = React.useState<MediaT>(findMQuery())
    const [componentConf, setComponentConf] = React.useState<UIComponentConf>(componentConfiguration)
    //const appConf = useAppContext()
    //const cConf = appConf.read('rain:ui')

    const handleChange = () => {
        const mq = findMQuery();
        const lConf = layoutConf && layoutConf[mq];
        //console.log('Change: ', lConf, mq)
        setFullLayout(_layoutBuilder(lConf ?? minimalLayout))
        setMediaQuery(mq);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleChange)
        window.addEventListener('change', handleChange)

        return () => {
            window.removeEventListener("resize", handleChange);
            window.removeEventListener("change", handleChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const layoutHandler = (layout: LayoutElement, component: React.ReactElement) => {
        switch (layout) {
            case 'HEADER':
                setHeaderComp(component)
                break
            case 'FOOTER':
                setFooterComp(component)
                break
            case 'LEFT-SIDE':
                setLeftSideComp(component)
                break
            case 'RIGHT-SIDE':
                setRightSideComp(component)
                break
            case 'BODY':
                setBodyComp(component)
                break
            case 'BREADCRUMB':
                setBreadcrumbComp(component)
                break
            case 'HELP':
                setHelpComp(component)
                break
            case 'NAVIGATION':
                setNavigationComp(component)
                break
            case 'SUB-NAVIGATION':
                setSubNavigationComp(component)
                break
        }
    }

    const _layoutBuilder = React.useCallback(
        (layout: LayoutBuilderElement, key?: number) => {
            const components: React.ReactElement[] = []
            switch (layout.element) {
                case 'header':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {headerComp}
                        </Flex>
                    )

                case 'footer':
                    return (
                        <Flex key={key}  {...layout.options }>
                            {footerComp}
                        </Flex>
                    )
                case 'body':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {bodyComp}
                        </Flex>
                    )
                case 'leftSide':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {leftSideComp}
                        </Flex>
                    )
                case 'rightSide':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {rightSideComp}
                        </Flex>
                    )
                case 'breadcrumb':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {breadcrumbComp}
                        </Flex>
                    )
                case 'help':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {helpComp}
                        </Flex>
                    )
                case 'navigation':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {navigationComp}
                        </Flex>
                    )
                case 'subNavigation':
                    return (
                        <Flex key={key} { ...layout.options }>
                            {subNavigationComp}
                        </Flex>
                    )
                case 'horizontal': {
                    layout.elements?.forEach((l: LayoutBuilderElement, k: number) => {
                        if (l !== undefined) components.push(_layoutBuilder(l, k))
                    })
                    return (
                        <Columns key={key} { ...layout.options }>
                            {components}
                        </Columns>
                    )
                }
                case 'vertical': {
                    layout.elements?.forEach((l: LayoutBuilderElement, k: number) => {
                        if (l !== undefined) components.push(_layoutBuilder(l, k))
                    })
                    return (
                        <Rows key={key} { ...layout.options }>
                            {components}
                        </Rows>
                    )
                }
            }
            return <></>
        },
        [
            bodyComp,
            breadcrumbComp,
            footerComp,
            headerComp,
            helpComp,
            leftSideComp,
            rightSideComp,
            navigationComp,
            subNavigationComp,
        ]
    )

    React.useEffect(() => {

        const lConf = layoutConf && layoutConf[mediaQuery];
        setFullLayout(_layoutBuilder(lConf ?? minimalLayout))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConf, _layoutBuilder])


    React.useEffect(() => {
        setComponentConf(componentConfiguration)
    }, [componentConfiguration])

    const resetLayoutsHandler = (elements: LayoutElement[]) => {
        elements.forEach((e) => {
            switch (e) {
                case 'LEFT-SIDE':
                    setLeftSideComp(undefined)
                    break
                case 'RIGHT-SIDE':
                    setRightSideComp(undefined)
                    break

                case 'BREADCRUMB':
                    setBreadcrumbComp(undefined)
                    break
            }
        })
    }

    const layoutElementsHandler = (settings: Record<LayoutElement, React.ReactElement>) => {
        for (let k in settings) {
            layoutHandler(k as keyof typeof settings, settings[k as keyof typeof settings])
        }
    }

    return (
        <LayoutContext.Provider
            value={{
                componentConfiguration: componentConf,
                media: mediaQuery,
                header: headerComp,
                breadcrumb: breadcrumbComp,
                footer: footerComp,
                body: bodyComp,
                leftSide: leftSideComp,
                rightSide: rightSideComp,
                resetLayouts: resetLayoutsHandler,
                setLayoutElement: layoutHandler,
                setLayoutElements: layoutElementsHandler,
            }}
        >
            {fullLayout}
        </LayoutContext.Provider>
    )
}

const minimalLayout: LayoutBuilderElement = {
    element: 'vertical',
    options: { gap: '10', minHeight: '100vh', justifyContent: 'space-between'},
    elements: [
        {
            element: 'vertical',
            options: { gap: '20' },
            elements: [
                {
                    element: 'vertical',
                    options: { gap: '0' },
                    elements: [
                        {
                            element: 'header',
                            options: {
                                width: '100vw',
                                backgroundColor: '#dedede',
                                alignItems: 'flex-start',
                                color: 'white',
                            },
                        },

                        {
                            element: 'breadcrumb',
                            options: {
                                width: '100vw',
                                paddingLeft: '20px',
                                backgroundColor: 'white',
                                color: 'black',
                                fontWeight: '700',
                                fontSize: '1rem',
                            },
                        },
                    ],
                },

                {
                    element: 'body',
                    options: { display: 'flex',  width: '90vw',  alignSelf: 'stretch'},
                },
            ],
        },

        {
            element: 'footer',
            options: {
                minHeight: '10vh',
                width: '100vw',
                display: 'flex',
                backgroundColor: 'white',
                color: 'black',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    ],
}

export const useMediaQuery = (): MediaT => {
    const layoutManager = React.useContext(LayoutContext);
    return layoutManager.media;
}

export const useComponentConfiguration = (): UIComponentConf => {
    const layoutManager = React.useContext(LayoutContext);
    return layoutManager.componentConfiguration;
}
import React from 'react';
import Keycloak from "keycloak-js";
import '@fontsource/titillium-web/300.css';
import '@fontsource/titillium-web/400.css';
import '@fontsource/titillium-web/600.css';
import '@fontsource/titillium-web/700.css';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {defaultLocale, LocaleManager, useTranslation} from "@nic-react-i18n/core";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Splash} from "./components/view/loader/Splash";
import {useConfiguration} from "@nic-react-i18n/i18next";
import {RealmDashboard} from "./components/view/RealmSwitcher";
import {AuthError, AuthEvent, AuthProvider} from "./context/Auth";
import {KeycloakConf} from "./keycloak";
import {LayoutManager, useComponentConfiguration} from "./layout/LayoutManager";
import {defaultLayout} from "./configurations/layout";
import {ApplicationStore, useAppContext} from "./context/AppContext/ApplicationContext";
import {UIMapping} from "@nic-react-ui/vanilla";
import {Columns, Div, Flex, UIManager} from "@nic-react-ui/core";
import {useConfigureUI} from "./hook/ui-hook";
import {LogoMini} from "./components/Logo/Logo";
import {TitledPaper} from "./components/layout/TitledPaper";
import {LanguageSwitcher} from "./components/language switcher/LanguageSwitcher";
import {useLocalStorage} from "./hook/localstorage";
import {MoonIcon, SunIcon} from "./icons";
import {MenuComponents} from "./components/menu";
import {AppConf} from "./AppConf";

export const originUrl = window.location.origin

const App = React.lazy(() => import("./App"));
export const ROOT_PATH =  process.env.PUBLIC_URL;
export const __DEV__: boolean = process.env.NODE_ENV === 'development'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 1000*60*10
        },
    },
});


interface RealmMainI {
    keycloak: Keycloak
}

const getRealm = (pathname: string): string => {
    return pathname.split('/')[1]
}


const RealmMain: React.FC<RealmMainI> = (props) => {
    const {location} = window
    const realm = getRealm(location.pathname)
    const scopes = AppConf.getRealmConf(realm).scopes.join(' ')

    const {keycloak} = props
    return (
        <React.Suspense fallback={<Splash message={'RAIN'}/> }>

            <QueryClientProvider client={queryClient}>
                {/*<ReactQueryDevtools initialIsOpen={false}*/}
                <AuthProvider
                    loadingComponent={<Splash message={'Loading'} />}
                    scope={scopes}
                    authClient={keycloak}
                    onEvent={(event: AuthEvent) => {
                        //console.log('Event: ', event);
                        switch (event) {
                            case "onAuthLogout":
                               // console.log('onAuthLogout: ', keycloak.authenticated)
                                if (!keycloak.authenticated) {
                                    //console.log('TokenLogout');
                                    keycloak.login({redirectUri: originUrl})
                                } else {
                                    keycloak.logout({redirectUri: originUrl})
                                }
                                break;
                            case "onReady":
                                break;
                            case "onTokenExpired":
                               // console.log('TokenExpired', );
                                (window as any)['keycloak'] = keycloak;
                                break;
                            case "onAuthSuccess":
                                (window as any)['keycloak'] = keycloak;
                                break;
                        }
                    }}
                    onError={(errorEvt: string, error?: AuthError) => {
                        console.error('ErrorEvt: ', errorEvt, ' error: ', error);
                        (window as any)['keycloak'] = undefined;
                        if (keycloak) {
                            keycloak.login({redirectUri: originUrl})
                        }
                    }}
                >

                    <App/>
                </AuthProvider>
            </QueryClientProvider>
        </React.Suspense>
    )
}

const MiniHeader: React.FC<any> = () => {
    const {t} = useTranslation()
    const appContext =  useAppContext();

    const uiConf = useComponentConfiguration()
    const [theme, setTheme] = useLocalStorage(`rain:theme:switch`);

    const themeIcon = (theme === 'light' ?
        <MoonIcon onClick={() => {appContext.write('currentTheme', 'dark'); setTheme('dark')}}/>:
        <SunIcon onClick={() => {appContext.write('currentTheme', 'light');setTheme('light')}}/>)
    const menuCss: MenuComponents = uiConf['menu'] as MenuComponents

    //const headerConf: React.CSSProperties = uiConf['header'] as React.CSSProperties
    const logoConf: React.CSSProperties = uiConf['logo'] as React.CSSProperties
    console.log('LogoConf: ', logoConf)



    return (
        <Div width={'100%'} marginTop={'20px'} marginLeft={'10px'} marginRight={'10px'}>

            <Flex direction={'row'} width={'100%'} justifyContent={'space-between'}>
                <Div width={'fit-content'}>
                    <TitledPaper asCard={false} role={'highest'} height={'fitContent'}>
                        <Columns gap={'20px'} justifyContent={'center'} alignItems={'center'}>
                            <LogoMini
                                ratio={200}
                                color={logoConf.color}
                                // fill={logoConf.fill}
                                // stroke={logoConf.stroke}
                            />
                            <Div fontWeight={'900'} fontSize={'2rem'}>{t('title')}</Div>
                            <img height={'30px'} width={'auto'} src={'/logoRegistroNew.svg'} alt={'logo registro'}/>
                        </Columns>
                    </TitledPaper>

                </Div>
                <Div width={'fit-content'} marginRight={'10px'}>
                    <TitledPaper  asCard={false} role={'highest'} width={'fit-content'}>
                        <Columns  gap={'20px'}>
                            <LanguageSwitcher/>
                            <div
                                css={{
                                    padding: '10px',
                                    '&:hover': {
                                        borderRadius: '20px', backgroundColor: menuCss.hover.backgroundColor,
                                    }
                                }}
                            >
                                {themeIcon}
                            </div>
                        </Columns>
                    </TitledPaper>
                </Div>
            </Flex>
        </Div>
    )
}
interface MainSwitchI {}
const MainSwitch: React.FC<MainSwitchI> = () => {
    useConfigureUI('switch')

    const appConf = useAppContext();
    //const currentTheme = appConf.read('currentTheme') ?? 'light'
    const componentConf = appConf.read('rain:ui')

    if (componentConf !== undefined) {

        return (
            <LayoutManager
                header={<MiniHeader/>}
                body={<RealmDashboard election={undefined}/>}
                footer={<></>}
                layoutConf={defaultLayout}
                componentConfiguration={componentConf}
            />
        )
    } else {
        return <></>
    }
}

interface MainI {}
const Main: React.FC<MainI> = () => {
    const [comp, setComp] = React.useState<React.ReactElement>(<App/>)
    const { pathname } = window.location

    React.useEffect(() => {
        const kc = KeycloakConf.getCurrentKeycloak()

        if (kc) {
            setComp(<RealmMain keycloak={kc}/>)
        } else {
            setComp(
                <ApplicationStore>
                    <UIManager configuration={UIMapping} update={() => {
                    }}>
                        <MainSwitch/>
                    </UIManager>
                </ApplicationStore>
            )
        }

    }, [pathname])

    return (
        <LocaleManager locale={defaultLocale()} impl={useConfiguration()}>
            {comp}
        </LocaleManager>
    )
}

root.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {useAppContext} from "../context/AppContext/ApplicationContext";
import {useLocalStorage} from "./localstorage";
import {componentConfiguration, DefaultTonalPalette, generateDesignTokens} from "../configurations/component-conf";

/**
 * This Hook can be used for detecting clicks outside the Opened Menu
 */
export const useClickOutside = (ref: React.RefObject<HTMLDivElement>, onClickOutside: ()=>void) => {
    React.useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }
        // Bind
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // dispose
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

const getBrowserColorScheme = () => {

    if (window.matchMedia) {
        // Check if the dark-mode Media-Query matches
        if(window.matchMedia('(prefers-color-scheme: dark)').matches){
            return ('dark')
        } else {
            return ('light')
        }
    } else {
        // Default (when Media-Queries are not supported)
        return ('light')
    }
}

export const useConfigureUI = (preferred_username: string) => {
    const appConf = useAppContext();

    //console.log('PreferredUSERNAME: ', preferred_username)

    const [theme, setTheme] = useLocalStorage(`rain:theme:${preferred_username}`, getBrowserColorScheme())
    const currentTheme = appConf.read('currentTheme') ?? theme
    const [
        tonalPalette,
    //    setTonalPalette
    ] = useLocalStorage(`rain:tonalPalette:${preferred_username}`, DefaultTonalPalette)
    const [themeTokens, setThemeTokens] = useLocalStorage(`rain:themeTokens:${theme}:${preferred_username}`, generateDesignTokens(currentTheme, tonalPalette??DefaultTonalPalette))

    const [ui, setUi] =
        useLocalStorage(`rain:uiSettings:${preferred_username}`, componentConfiguration(themeTokens??generateDesignTokens(currentTheme, tonalPalette??DefaultTonalPalette)));



    React.useEffect(() => {
        appConf.write('currentTheme', currentTheme)
        // Inserito a livello di body perchè espansione del menu porterebbe a colorare solamente la view port e quando
        // la pagina aumenta verticalmente (scroll) allora risulterebbe non colorata
        document.body.setAttribute('style', `background-color:${themeTokens.surfaceContainer}`)
        document.getElementById('root')?.setAttribute('style', `height: 100vh; background-color:${themeTokens.surfaceContainer}`)
        appConf.write('currentThemeTokens', themeTokens)

        appConf.write('rain:ui', ui)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
       // console.log('CurrentTheme: ', currentTheme, theme)
        if (currentTheme !== undefined) {
            if (theme !== currentTheme) {
               // console.log('CurrentTheme2: ', currentTheme, theme)
                setTheme(currentTheme)
                // appConf.write('currentTheme', currentTheme);
                const themeTokens = generateDesignTokens(currentTheme, tonalPalette??DefaultTonalPalette)
                setThemeTokens(themeTokens)

                appConf.write('currentThemeTokens', themeTokens)

                const componentConf = componentConfiguration(themeTokens)
                appConf.write('rain:ui', componentConf)

                setUi(componentConf)
                document.body.setAttribute('style', `background-color:${themeTokens.surfaceContainer}`)
                document.getElementById('root')?.setAttribute('style', `background-color:${themeTokens.surfaceContainer}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTheme])



}
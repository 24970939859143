/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";

interface ChevronUpI extends IconI {}

export const ChevronUp: React.FC<ChevronUpI> = (props) => {
    const [ratioX, ratioY] = ratioXY(16,16, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                height={ratioY}
                width={ratioX}
            >
                <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                />
            </svg>
        </div>
    )
}
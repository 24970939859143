/** @jsxImportSource @emotion/react */
import React from "react";
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";
import {keyframes} from "@emotion/react";

const spinner = keyframes`
    to {transform: rotate(360deg);}
`

export const SpinnerIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY),{animation: `${spinner} .6s linear infinite`}]}
            onClick={props.onClick}
        >
            <svg
                fill="none"
                viewBox="0 0 24 24"
                height={ratioX}
                width={ratioY}
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 3c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                    clipRule="evenodd"
                    opacity={0.2}
                />
                <path
                    fill="currentColor"
                    d="M2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
                />
            </svg>
        </div>
    )
}
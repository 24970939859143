/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

interface UserIconI extends IconI {
    type?: string
}

const UserType: Record<string, any> = {
    'registrarPasswordChange': (  <path
        style={{fill:'#b3b3b3', stroke:'#000000', strokeWidth:1, strokeLinejoin:'round', strokeMiterlimit:4, strokeDasharray:'none'}}
        d="m 86.621349,127.48443 c -0.816962,-0.17328 -3.192609,-1.34652 -4.918447,-2.42902 -7.082779,-4.44255 -12.329053,-11.26286 -15.24925,-19.82445 -1.468439,-4.30526 -2.334092,-8.911942 -2.458248,-13.081919 -0.06863,-2.305032 0.01006,-2.851236 0.583037,-4.045211 0.515736,-1.074754 1.58768,-2.186938 2.617527,-2.715791 0.382544,-0.196443 4.988002,-2.183157 10.234342,-4.414905 l 9.538808,-4.05774 0.894262,-0.0012 0.894263,-0.0012 8.79358,3.741955 c 4.836477,2.058073 9.281577,3.950504 9.877977,4.205388 0.59642,0.254896 1.33655,0.634683 1.64473,0.843971 1.19644,0.812478 2.17743,2.194644 2.54045,3.579331 0.14477,0.552214 0.16091,0.962779 0.11135,2.831836 -0.0832,3.137815 -0.50438,6.070103 -1.36284,9.489535 -2.87451,11.44987 -9.7784,20.4359 -19.27019,25.08187 -1.890172,0.92519 -2.966104,1.11719 -4.471315,0.79792 z"
        id="path898" />)
};

export const UserIcon: React.FC<UserIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24,   props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height={ratioY}
            width={ratioX}
        >
            <path d="M12 2A10.13 10.13 0 002 12a10 10 0 004 7.92V20h.1a9.7 9.7 0 0011.8 0h.1v-.08A10 10 0 0022 12 10.13 10.13 0 0012 2zM8.07 18.93A3 3 0 0111 16.57h2a3 3 0 012.93 2.36 7.75 7.75 0 01-7.86 0zm9.54-1.29A5 5 0 0013 14.57h-2a5 5 0 00-4.61 3.07A8 8 0 014 12a8.1 8.1 0 018-8 8.1 8.1 0 018 8 8 8 0 01-2.39 5.64z" />
            <path d="M12 6a3.91 3.91 0 00-4 4 3.91 3.91 0 004 4 3.91 3.91 0 004-4 3.91 3.91 0 00-4-4zm0 6a1.91 1.91 0 01-2-2 1.91 1.91 0 012-2 1.91 1.91 0 012 2 1.91 1.91 0 01-2 2z" />
        </svg>
            {props.type && UserType[props.type]}
        </div>

    )
}
export const ratioXY = (x: number, y: number, ratio: number) => {
    const ratioX = x * ( ratio ?? 100) / 100
    const ratioY = y * (ratio ?? 100) / 100
    return [ratioX, ratioY]
}
export const defaultIconStyle = (ratioX: number, ratioY: number) => {
    return ({
        height: `${ratioY}px`,
        width: `${ratioX}px`,
        display: 'flex'
    })
}
export * from './types'
export * from './ArrowLeft'
export * from './ArrowRight'
export * from './GlobeIcon'
export * from './EditIcon'
export * from './PDFIcon'
export * from './CheckIcon'
export * from './EditIconAlt'
export * from './ToggleOffIcon'
export * from './ToggleOnIcon'
export * from './CreditIcon'
export * from './TransferIcon'
export * from './ErrorMessageIcon'
export * from './InfoMessageIcon'
export * from './ThumbUp'
export * from './ThumbDown'
export * from './ChevronDown'
export * from './ChevronUp'
export * from './MenuIcon'
export * from './AddUserIcon'
export * from './HelpIcon'
export * from './DeleteIcon'
export * from './UserIcon'
export * from './IpIcon'
export * from './TimeIcon'
export * from './SearchIcon'
export * from './CancelIcon'
export * from './OrgIcon'
export * from './ToolIcon'
export * from './MoneyIcon'
export * from './MegaphoneIcon'
export * from './ForkLiftIcon'
export * from './ContactIcon'
export * from './SunIcon'
export * from './MoonIcon'
export * from './RainLogoBold'
export * from './RainLogo'
export * from './UndoIcon'
export * from './CancelSimpleIcon'
export * from './PlusIcon'
export * from './GenericIcon'
export * from './Shield'
export * from './EditTimeIcon'
export * from './BackIIcon'
export * from './ReloadIcon'
export * from './DotIcon'
export * from './DotFillIcon'
export * from './CandidateIcon'
export * from './OpenIcon'
export * from './Calendarcon'
export * from './TXTIcon'
export * from './CloseIcon'
export * from './SpinnerIcon'
export * from './GavelIcon'
export * from './LockIcon'
export * from './HourGlass'
export * from './BadgeIcon'
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";

export const TimeIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path d="M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16zm1-8.41l2.54 2.53a1 1 0 01-1.42 1.42L11.3 12.7a1 1 0 01-.3-.7V8a1 1 0 012 0v3.59z" />
            </svg>
        </div>
    )
}
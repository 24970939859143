interface RealmI {
    configPath: string
    file: string
    redirect: string
    scopes: string[]
}
export class AppConf {
    private static instance: AppConf
    private deploy: string
    private homeUrl: string
    private application: string
    private applications: any
    private realms: Record<string, RealmI>
    private idp_user_password_change_url: Record<string, string>
    private idp_user_update_url: Record<string, string>
    private api_gateway_url: string
    private uploadMaxFileSize: number
    private maxProgramCharacters: number
    private api_gateway_path: string

    private constructor()  {
        const {elections} = window as any;
        this.idp_user_update_url = elections['idp_user_update_url']
        this.idp_user_password_change_url = elections['idp_user_password_change_url']
        this.deploy = elections['deploy']
        this.homeUrl = elections['homeUrl']
        this.applications = elections['applications']
        this.realms = elections['realms']
        this.application = elections['application']
        this.api_gateway_url = elections['api_gateway_url']
        this.uploadMaxFileSize = elections['uploadMaxFileSize']
        this.maxProgramCharacters = elections['maxProgramCharacters']
        this.api_gateway_path = elections['api_gateway_path']
    }

    public static getInstance() {
        if (!AppConf.instance) {
            AppConf.instance = new AppConf()
        }
        return AppConf.instance
    }


    public static getIdpUserPasswordChangeUrl(realm: string) {
        return AppConf.getInstance().idp_user_password_change_url[realm]
    }
    public static getIdpUserUpdateUrl(realm: string) {
        return AppConf.getInstance().idp_user_update_url[realm]
    }



    public static getApplications() {
        return AppConf.getInstance().applications
    }

    public static getDeploy(): string {
        return AppConf.getInstance().deploy
    }

    public static getHomeUrl(): string {
        return AppConf.getInstance().homeUrl
    }


    public static getApiGatewayPath(): string {
        return AppConf.getInstance().api_gateway_path
    }

    public static getRealms(): Record<string, RealmI> {
        return AppConf.getInstance().realms
    }

    public static getRealmConf(realm: string): RealmI {
        return AppConf.getInstance().realms[realm]
    }

    public static getApplication(): string {
        return AppConf.getInstance().application
    }
    public static getApiGatewayUrl(): string {
        return AppConf.getInstance().api_gateway_url
    }

    public static getUploadMaxFileSize(): number {
        return AppConf.getInstance().uploadMaxFileSize
    }

    public static getMaxProgramCharacters(): number {
        return AppConf.getInstance().maxProgramCharacters
    }
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";

export const NoCandidateIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(6.3452724, 6.3361746, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                width={ratioX}
                height={ratioY}
                viewBox="0 0 6.3452724 6.3361746"
                version="1.1"
                id="svg1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs
                    id="defs1"/>
                <g
                    id="layer1"
                    transform="translate(-108.75325,-72.500872)">
                    <path
                        d="m 111.90804,72.516996 a 1.6631707,1.6631707 0 1 0 1.66318,1.663172 1.6631707,1.6631707 0 0 0 -1.66318,-1.663172 z m 0,2.661075 a 0.99790244,0.99790244 0 1 1 0.99791,-0.997903 0.99790244,0.99790244 0 0 1 -0.99791,0.997903 z m 2.99371,3.658975 v -0.332634 a 2.3284389,2.3284389 0 0 0 -2.32844,-2.328438 h -1.33055 a 2.3284389,2.3284389 0 0 0 -2.32843,2.328438 v 0.332634 h 0.66528 v -0.332634 a 1.6631707,1.6631707 0 0 1 1.66315,-1.663171 h 1.33055 a 1.6631707,1.6631707 0 0 1 1.66316,1.663171 v 0.332634 z"
                        id="path1"
                        style={{fill: 'currentColor', opacity: 0.7, strokeWidth: 0.332634}}
                    />
                    <rect
                        style={{fill: 'currentColor', stroke: 'none', strokeWidth: 4.50778}}
                        id="rect1"
                        width="8.7149754"
                        height="0.41927114"
                        x="128.26917"
                        y="-25.738323"
                        rx="0.1125469"
                        ry="0.41927114"
                        transform="matrix(0.70768943,0.70652365,-0.70772938,0.70648363,0,0)"/>
                </g>
            </svg>
        </div>
    )
}


export const CandidateIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                height={ratioX}
                width={ratioY}
            >
                <circle cx="12" cy="8" r="5"/>
                <path d="M20 21a8 8 0 0 0-16 0"/>
            </svg>
        </div>
    )
}


export const CandidateEndorsmentIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >


            <svg
                viewBox="0 0 24 24"
                height={ratioX}
                width={ratioY}
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M2 21a8 8 0 0 1 10.434-7.62"/>
                <circle cx="10" cy="8" r="5"/>
                <circle cx="18" cy="18" r="3"/>
                <path d="m19.5 14.3-.4.9"/>
                <path d="m16.9 20.8-.4.9"/>
                <path d="m21.7 19.5-.9-.4"/>
                <path d="m15.2 16.9-.9-.4"/>
                <path d="m21.7 16.5-.9.4"/>
                <path d="m15.2 19.1-.9.4"/>
                <path d="m19.5 21.7-.4-.9"/>
                <path d="m16.9 15.2-.4-.9"/>
            </svg>
        </div>
    )
}
export const CandidateVoteIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >

            <svg
                viewBox="0 0 24 24"
                height={ratioX}
                width={ratioY}
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M2 21a8 8 0 0 1 13.292-6"/>
                <circle cx="10" cy="8" r="5"/>
                <path d="m16 19 2 2 4-4"/>
            </svg>

        </div>
    )
}
export const CandidateResultsIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                height={ratioX}
                width={ratioY}
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"

            >
                <path
                    d="M7.21 15L2.66 7.14a2 2 0 01.13-2.2L4.4 2.8A2 2 0 016 2h12a2 2 0 011.6.8l1.6 2.14a2 2 0 01.14 2.2L16.79 15M11 12L5.12 2.2M13 12l5.88-9.8M8 7h8"/>
                <path d="M17 17 A5 5 0 0 1 12 22 A5 5 0 0 1 7 17 A5 5 0 0 1 17 17 z"/>
                <path d="M12 18v-2h-.5"/>

            </svg>
        </div>
    )
}
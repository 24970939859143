/** @jsxImportSource @emotion/react */
import React from "react";
import {IconI} from "./types";
import {defaultIconStyle, ratioXY} from "./index";
import {divCSS} from "@nic-react-ui/core";


export const DotFillIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height={ratioX}
                width={ratioY}
            >
                <path d="M12 18a6 6 0 100-12 6 6 0 000 12z"/>
            </svg>
        </div>
    )
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";


export const DeleteIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(16, 16, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
            title={props.title}
        >
            <svg
                viewBox="0 0 16 16"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path d="M11 1.5v1h3.5a.5.5 0 010 1h-.538l-.853 10.66A2 2 0 0111.115 16h-6.23a2 2 0 01-1.994-1.84L2.038 3.5H1.5a.5.5 0 010-1H5v-1A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5zm-5 0v1h4v-1a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5zM4.5 5.029l.5 8.5a.5.5 0 10.998-.06l-.5-8.5a.5.5 0 10-.998.06zm6.53-.528a.5.5 0 00-.528.47l-.5 8.5a.5.5 0 00.998.058l.5-8.5a.5.5 0 00-.47-.528zM8 4.5a.5.5 0 00-.5.5v8.5a.5.5 0 001 0V5a.5.5 0 00-.5-.5z" />
            </svg>
        </div>
    )
}
/** @jsxImportSource @emotion/react */
import React from "react";
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

interface InfoMessageIconI extends IconI {}

export const InfoMessageIcon: React.FC<InfoMessageIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100);
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height={ratioX}
                width={ratioY}
            >
                <path d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
            </svg>
        </div>
    )

}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

interface HelpIconI extends IconI {
}

export const HelpIcon: React.FC<HelpIconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio ?? 100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                height={ratioX}
                width={ratioY}
            >
                <path d="M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z" />
                <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3M12 17h.01" />
            </svg>
        </div>
    )
}
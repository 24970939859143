// FIELD
export const FIELD_GAP = '5px'
export const FIELD_FONT_SIZE = '1rem'
export const FIELD_LABEL_COLOR = '#9c9c9c'
export const FIELD_LABEL_WEIGHT = '700'
export const FIELD_VALUE_COLOR = 'black'
export const FIELD_VALUE_WEIGHT = '400'
export const FIELD_PADDING = '20px'
export const FIELDS_GAP = '20px'
export const SUB_FIELDS_GAP = '10px'


export const LINK_COLOR = "rgb(112, 158, 175)"
export const LINK_DISABLED = "#b7b7b7"
export const BREADCRUMB_SEPARATOR = "/";

export const PAPER_TITLE_GAP = '25px'
export const PAPER_TITLE_FONT_SIZE = '1.1rem'
export const PAPER_TITLE_FONT_WEIGHT = '700'
export const PAPER_TITLE_FONT_COLOR = '#709eaf'
export const PAPER_PADDING = '15px'
export const PAPER_MARGIN_LEFT = '20px'
export const PAPER_BACKGROUND_COLOR = "#efefef"
export const INNER_TITLE_PAPER_BORDER = `1px solid ${LINK_COLOR}`
export const INNER_TITLE_PAPER_BORDER_RADIUS = '5px'

export const TABLE_BORDER_COLOR = "#dedede"
export const TABLE_BORDER_RADIUS = "5px"
export const TABLE_GAP = "20px"

export const MENU_GAP = '10px'
export const ERROR_COLOR = '#e02a2a'
export const DISABLED_COLOR = '#efefef'
export const DISABLED_BORDER = '#dcdcdc'
export const DISABLED_TEXT =  "#C9C9C9"
export const BORDER_COLOR = 'rgb(112, 158, 175, 0.3)'
export const BORDER_COLOR_ERROR = '#e1adad'

export const TAG_OK_COLOR= "rgb(133,171,131)"
export const TAG_OK_BACKGROUND_COLOR= "rgb(133,171,131, 0.2)"
export const TAG_NOK_BACKGROUND_COLOR = "rgba(206,124,124,0.2)"
export const TAG_NOK_COLOR = "#ce7c7c"
export const TAG_INFO_COLOR = "rgb(112, 158, 175)"
export const TAG_INFO_BACKGROUND_COLOR = "rgb(112, 158, 175, 0.2)"
 import React from 'react'

const getStorageValue = (key: string, defaultValue?: any) => {
    // getting stored value
    const saved = localStorage.getItem(key) ?? undefined;
    //console.log('GetStorageValue: ', typeof saved, (saved !== undefined))
    const initial = (saved !== undefined && saved !== 'undefined') ? JSON.parse(saved) : undefined;
    return initial ?? defaultValue;
}

export const useLocalStorage = (key: string, defaultValue?: any) => {
    const [value, setValue] = React.useState(() => {
        return getStorageValue(key, defaultValue);
    });

    React.useEffect(() => {
        // storing input name
        if (value !== undefined) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    return [value, setValue];
};
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";

export const OrgIcon: React.FC<IconI> = (props) => {
    const [ratioX, ratioY] = ratioXY(24, 24, props.ratio??100)
    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height={ratioY}
                width={ratioX}
            >
                <path d="M6.25 12a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM5.5 9.25a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM6.25 5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM9 12.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zm.75-4.25a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM9 5.75A.75.75 0 019.75 5h.5a.75.75 0 010 1.5h-.5A.75.75 0 019 5.75zM13.25 12a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zm-.75-2.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM13.25 5a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5z" />
                <path
                    fillRule="evenodd"
                    d="M2 20a2 2 0 002 2h3.75a.75.75 0 00.75-.75V19h3v2.25c0 .414.336.75.75.75H16c.092 0 .183-.006.272-.018a.753.753 0 00.166.018H20a2 2 0 002-2v-8a2 2 0 00-.8-1.6l-.5-.375a.75.75 0 00-.9 1.2l.5.375a.5.5 0 01.2.4v8a.5.5 0 01-.5.5h-2.063c.041-.16.063-.327.063-.5V3a2 2 0 00-2-2H4a2 2 0 00-2 2v17zm2 .5a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v17a.5.5 0 01-.5.5h-3v-2.25a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75v2.25H4z"
                />
            </svg>
        </div>
    )
}

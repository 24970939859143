/** @jsxImportSource @emotion/react */
import React from 'react'
import './loader.css'
import {LogoMini} from "../../Logo/Logo";
import {useAppContext} from "../../../context/AppContext/ApplicationContext";
import {ThemeTokens} from "../../../configurations/component-conf";

interface LoaderI {
    ratio?: number;
    backgroundColor?: string
}

export const Loader: React.FC<LoaderI> = (props) => {
    const appConf = useAppContext()
    const themeTokens: ThemeTokens = appConf.read('currentThemeTokens') as ThemeTokens

    return (
        <div css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            backgroundColor: 'transparent'
        }}
        >
            <LogoMini ratio={props.ratio ?? 35} color={themeTokens?.primary??'rgb(0, 101, 142)'}/>
        </div>
    )
}
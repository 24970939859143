/** @jsxImportSource @emotion/react */
import React from "react";
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";
interface TXTIconI extends IconI {

}
export const TXTIcon: React.FC<TXTIconI> = (props) => {
    const [ratioX, ratioY]  = ratioXY(16, 16, props.ratio ?? 100)

    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                height={ratioY}
                width={ratioX}
            >
                <path
                    d="M5.5 7a.5.5 0 000 1h5a.5.5 0 000-1h-5zM5 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm0 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5z"/>
                <path
                    d="M9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5z"/>
            </svg>

        </div>
    )
}
/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {defaultIconStyle} from "./index";
import {divCSS} from "@nic-react-ui/core";

interface GenericIconI extends IconI {
    svgPath: string[]
    viewBox: string
    height: string
    width: string
}

export const GenericIcon: React.FC<GenericIconI> = (props) => {
    const {height, width, viewBox, svgPath} = props
    return (
        <div
            css={[divCSS(props), defaultIconStyle(+height, +width)]}
            onClick={props.onClick}
        >
            <svg
                viewBox={viewBox}
                fill="currentColor"
                stroke={'currentColor'}
                height={height}
                width={width}
            >
                {svgPath.map((path: string, index: number) => (<path key={index} d={path}/>))}
            </svg>
        </div>
    )
}
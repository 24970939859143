/** @jsxImportSource @emotion/react */
import React from "react";
import {DivI} from "@nic-react-ui/core";
import {useComponentConfiguration} from "../../layout/LayoutManager";
import _ from 'lodash'
import {QueryFunction, QueryKey, useQuery, UseQueryResult} from "@tanstack/react-query";
import {ReloadIcon} from "../../icons";

interface ButtonI extends DivI  {
    title?: string
    icon?: React.ReactElement
    type?: 'submit' | 'button' | 'reset'
    disabled?: boolean
}

export const Button: React.FC<ButtonI> = (props) => {
    const uiConf = useComponentConfiguration();
    //const buttonCss: React.CSSProperties = {...(uiConf['button'] as React.CSSProperties), ...(props as React.CSSProperties)}
    const bCss: React.CSSProperties = uiConf['button'] as React.CSSProperties
    const extraCss: React.CSSProperties = _.pick(props, ['backgroundColor', 'color', 'border', "borderRadius", "padding", "margin"])
    const buttonCss: React.CSSProperties = {...bCss, ...extraCss}
    const disabled = props.disabled ?? false
    //const backgroundColor = props.disabled ? DISABLED_COLOR: 'white';
    //const borderColor = props.disabled ? DISABLED_BORDER: LINK_COLOR;
    //const border = props.border ?? `2px solid ${borderColor}`
    const {backgroundColor, color} = buttonCss;
    const disabledBackgroundColor = buttonCss.backgroundColor+'2A'
    const bColor = props.disabled ? disabledBackgroundColor: backgroundColor;
    const disabledColor = buttonCss.backgroundColor+'4A'
    const cColor = props.disabled ? disabledColor : color;
    return (
        <button
            title={props.title}
            disabled={disabled}
            type={props.type ?? 'button'}
            onClick={props.onClick}
            css={[{...buttonCss},  {color: cColor}]}
            style={{border: 'none', backgroundColor: 'transparent', padding: '0px'}}>
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap:'10px',
                    border:`${buttonCss.border??'5px'}`,
                    padding:`${buttonCss.padding}`,
                    borderRadius:`${buttonCss.borderRadius}`,
                    backgroundColor:`${bColor}`,
                    alignItems:'center',
                    justifyContent:'center',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    height:`${buttonCss.height}`,
                    minHeight:props.height??'inherit'
                }}

            >
                {props.icon}
                {props.children}

            </div>
        </button>
    )
}


interface PromiseButtonI<T,V> extends ButtonI {
    query: UseQueryResult<T,V>
    onLoaded: (data: any) => void
}

export const PromiseButton: React.FC<PromiseButtonI<unknown, any>> = (props) => {
    const {query} = props
    if (query.isLoading) {
        return <Button icon={<ReloadIcon/>}>{props.children}</Button>
    }
    if (query.isError) {
        return <Button disabled={true} icon={props.icon}>{props.children}</Button>
    }

    return (
        <Button
            icon={props.icon}
            onClick={()=>{
                props.onLoaded(query.data)
            }}
        >
            {props.children}
        </Button>)

}

interface QueryButtonI extends ButtonI {
    queryKey: QueryKey
    queryFn: QueryFunction
    enabled?: boolean
    onLoaded: (data: any) => void
}

interface DummyComponentI {
    data: any;
    onLoaded: (data: any) => void
}

const DummyComponent: React.FC<DummyComponentI> = (props) => {
    React.useEffect(()=>{
        props.onLoaded(props.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <></>
    )
}

/**
 * QueryButton: la query viene attivata solo dopo aver premuto il pulsante
 * @param props
 * @constructor
 */
export const QueryButton: React.FC<QueryButtonI> = (props) => {
    const {queryKey, queryFn, enabled} = props
    const [clicked, setClicked] = React.useState(false)
    const queryData = useQuery({queryKey: queryKey, queryFn: queryFn, enabled: enabled && clicked})

    // console.log('QueryData: ', queryData)

    if (queryData.isError) {
        return <Button disabled={true} icon={props.icon}>{props.children}</Button>
    }

    if (queryData.data) {
        return <DummyComponent data={queryData.data} onLoaded={props.onLoaded}/>
    }

    if (queryData.isLoading) {
        return (
            <Button
                icon={props.icon}
                onClick={() => {
                    setClicked(true)
                }}
            >
                {props.children}
            </Button>)
    }
    return (
        <Button
            icon={props.icon}
            onClick={() => {
                setClicked(true)
            }}
        >
            {props.children}
        </Button>)
}
import React from 'react'
import {useTranslation} from "@nic-react-i18n/core";
import {Div} from "@nic-react-ui/core";
import {useAppContext} from "../../context/AppContext/ApplicationContext";
import {ThemeTokens} from "../../configurations/component-conf";

interface LanguageSwitcherI {
    color?: string
    backgroundColor?: string

}
export const LanguageSwitcher: React.FC<LanguageSwitcherI> = (props) => {
    const {locale, updateLocale} = useTranslation();
    const appConf = useAppContext()
    const themeTokens: ThemeTokens = appConf.read('currentThemeTokens') as ThemeTokens
    const backgroundColor = themeTokens.secondary
    const color = themeTokens.onSecondary
    return (
        <Div
            display={'flex'}
            justifyContent={'center'}
            cursor={'pointer'}
            borderRadius={'5px'}
            border={`2px ${backgroundColor} solid`}
            padding={'3px 5px 3px 5px'}
            backgroundColor={backgroundColor}
            color={color}
            width={'2rem'}
            fontWeight={'700'}
            onClick={() => {
                const newLocale = locale === 'it' ? 'en' : 'it'
                updateLocale(newLocale);
            }}
        >
            {locale.toUpperCase()}
        </Div>
    )
}
import Keycloak from 'keycloak-js'

export const ROOT_PATH =  process.env.PUBLIC_URL;
export const isRegistrar = window.location.pathname.startsWith('/registrar')
export const isElections = window.location.pathname.startsWith('/elections')


export const getKeycloak = () => {

    if (isElections) {
     return (new Keycloak(`${ROOT_PATH}/configurations/elections/keycloak-elections.json`))
    }

    if (isRegistrar) {

        return (new Keycloak(`${ROOT_PATH}/configurations/registrar/keycloak-registrar.json`))
    }
    return undefined
}


export class KeycloakConf {
    private static instance: KeycloakConf
    private currentKeycloak?: Keycloak
    private currentRealm?: string
    private constructor() {
        this.currentKeycloak = undefined
        this.currentRealm = undefined
    }
    public static getInstance() {
        if (!KeycloakConf.instance) {
            KeycloakConf.instance = new KeycloakConf()
        }

        if (isElections) {
            if (KeycloakConf.instance.currentRealm !== 'elections') {
                KeycloakConf.instance.currentKeycloak = new Keycloak(`${ROOT_PATH}/configurations/elections/keycloak-elections.json`)
                KeycloakConf.instance.currentRealm = 'elections'
            }
        }
        if (isRegistrar) {
            if (KeycloakConf.instance.currentRealm !== 'registrar') {
                KeycloakConf.instance.currentKeycloak = new Keycloak(`${ROOT_PATH}/configurations/registrar/keycloak-registrar.json`)
                KeycloakConf.instance.currentRealm = 'registrar'
            }
        }

        return KeycloakConf.instance
    }

    public static getCurrentKeycloak(): Keycloak|undefined {
        return KeycloakConf.getInstance().currentKeycloak
    }

    public static getCurrentRealm(): string|undefined {
        return KeycloakConf.getInstance().currentRealm
    }
}
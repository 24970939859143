import { argbFromHex, hexFromArgb, themeFromSourceColor } from "@material/material-color-utilities";
import {UIComponentConf} from "../layout/LayoutManager";

export type Tone = {
    [index: number]: string;
    '0': string;
    '4': string;
    '6': string;
    '10': string;
    '12': string;
    '17': string;
    '20': string;
    '22': string;
    '24': string;
    '30': string;
    '40': string;
    '50': string;
    '60': string;
    '70': string;
    '80': string;
    '87': string;
    '90': string;
    '92': string;
    '94': string;
    '95': string;
    '96': string;
    '98': string;
    '99': string;
    '100': string;
};

export type TonalPalette = {
    primary: Tone;
    secondary: Tone;
    tertiary: Tone;
    neutral: Tone;
    neutralVariant: Tone;
    error: Tone;
}



export type ThemeMode = 'light' | 'dark';

const getColorToken = (themeMode: ThemeMode, palette: Tone, lightLevel: number, darkLevel: number): string =>
    palette[themeMode === 'light' ? lightLevel : darkLevel];

export const generateDesignTokens = (themeMode: ThemeMode, tonalPalette: TonalPalette): ThemeTokens => {

    const { primary, secondary, tertiary, neutral, neutralVariant, error } = tonalPalette;

    const { customColors } = themeFromSourceColor(argbFromHex(tonalPalette.primary[40]), [
        {
            name: 'info',
            value: argbFromHex('#0288d1'),
            blend: true
        },
        {
            name: 'success',
            value: argbFromHex('#2e7d32'),
            blend: true
        },
        {
            name: 'warning',
            /*value: argbFromHex('#ed6c02'),*/
            value: argbFromHex('#f59e0b'),
            blend: true
        }
    ]);

    return {
        primary: getColorToken(themeMode, primary, 40, 80),
        onPrimary: getColorToken(themeMode, primary, 100, 20),
        primaryContainer: getColorToken(themeMode, primary, 90, 30),
        onPrimaryContainer: getColorToken(themeMode, primary, 10, 90),

        secondary: getColorToken(themeMode, secondary, 40, 80),
        onSecondary: getColorToken(themeMode, secondary, 100, 20),
        secondaryContainer: getColorToken(themeMode, secondary, 90, 30),
        onSecondaryContainer: getColorToken(themeMode, secondary, 10, 90),

        tertiary: getColorToken(themeMode, tertiary, 40, 80),
        onTertiary: getColorToken(themeMode, tertiary, 100, 20),
        tertiaryContainer: getColorToken(themeMode, tertiary, 90, 30),
        onTertiaryContainer: getColorToken(themeMode, tertiary, 10, 90),

        error: getColorToken(themeMode, error, 40, 80),
        onError: getColorToken(themeMode, error, 100, 20),
        errorContainer: getColorToken(themeMode, error, 90, 30),
        onErrorContainer: getColorToken(themeMode, error, 10, 90),

        primaryFixed: getColorToken(themeMode, primary, 90, 90),
        primaryFixedDim: getColorToken(themeMode, primary, 80, 80),
        onPrimaryFixed: getColorToken(themeMode, primary, 10, 10),
        onPrimaryFixedVariant: getColorToken(themeMode, primary, 30, 30),

        secondaryFixed: getColorToken(themeMode, secondary, 90, 90),
        secondaryFixedDim: getColorToken(themeMode, secondary, 80, 80),
        onSecondaryFixed: getColorToken(themeMode, secondary, 10, 10),
        onSecondaryFixedVariant: getColorToken(themeMode, secondary, 30, 30),

        tertiaryFixed: getColorToken(themeMode, tertiary, 90, 90),
        tertiaryFixedDim: getColorToken(themeMode, tertiary, 80, 80),
        onTertiaryFixed: getColorToken(themeMode, tertiary, 10, 10),
        onTertiaryFixedVariant: getColorToken(themeMode, tertiary, 30, 30),

        surface: getColorToken(themeMode, neutral, 98, 6),
        onSurface: getColorToken(themeMode, neutral, 10, 90),

        surfaceDim: getColorToken(themeMode, neutral, 87, 6),
        surfaceBright: getColorToken(themeMode, neutral, 98, 24),

        surfaceContainerLowest: getColorToken(themeMode, neutral, 100, 4),
        surfaceContainerLow: getColorToken(themeMode, neutral, 96, 10),
        surfaceContainer: getColorToken(themeMode, neutral, 94, 12),
        surfaceContainerHigh: getColorToken(themeMode, neutral, 92, 17),
        surfaceContainerHighest: getColorToken(themeMode, neutral, 90, 22),

        surfaceVariant: getColorToken(themeMode, neutralVariant, 90, 30),
        onSurfaceVariant: getColorToken(themeMode, neutralVariant, 30, 80),

        outline: getColorToken(themeMode, neutralVariant, 50, 60),
        outlineVariant: getColorToken(themeMode, neutralVariant, 80, 30),

        inverseSurface: getColorToken(themeMode, neutral, 20, 90),
        inverseOnSurface: getColorToken(themeMode, neutral, 95, 20),

        inversePrimary: getColorToken(themeMode, primary, 80, 40),
        inverseOnPrimary: '',

        shadow: getColorToken(themeMode, neutral, 0, 0),
        scrim: getColorToken(themeMode, neutral, 0, 0),

        surfaceTintColor: getColorToken(themeMode, primary, 40, 80),

        background: getColorToken(themeMode, neutral, 98, 6),
        onBackground: getColorToken(themeMode, neutral, 10, 90),

        info: hexFromArgb(customColors[0][themeMode].color),
        onInfo: hexFromArgb(customColors[0][themeMode].onColor),
        infoContainer: hexFromArgb(customColors[0][themeMode].colorContainer),
        onInfoContainer: hexFromArgb(customColors[0][themeMode].onColorContainer),

        success: hexFromArgb(customColors[1][themeMode].color),
        onSuccess: hexFromArgb(customColors[1][themeMode].onColor),
        successContainer: hexFromArgb(customColors[1][themeMode].colorContainer),
        onSuccessContainer: hexFromArgb(customColors[1][themeMode].onColorContainer),

        warning: hexFromArgb(customColors[2][themeMode].color),
        onWarning: hexFromArgb(customColors[2][themeMode].onColor),
        warningContainer: hexFromArgb(customColors[2][themeMode].colorContainer),
        onWarningContainer: hexFromArgb(customColors[2][themeMode].onColorContainer)
    };
}


export type ThemeTokens = {

    primary: string;
    onPrimary: string;
    primaryContainer: string;
    onPrimaryContainer: string;

    secondary: string;
    onSecondary: string;
    secondaryContainer: string;
    onSecondaryContainer: string;

    tertiary: string;
    onTertiary: string;
    tertiaryContainer: string;
    onTertiaryContainer: string;

    error: string;
    onError: string;
    errorContainer: string;
    onErrorContainer: string;

    primaryFixed: string;
    primaryFixedDim: string;
    onPrimaryFixed: string;
    onPrimaryFixedVariant: string;

    secondaryFixed: string;
    secondaryFixedDim: string;
    onSecondaryFixed: string;
    onSecondaryFixedVariant: string;

    tertiaryFixed: string;
    tertiaryFixedDim: string;
    onTertiaryFixed: string;
    onTertiaryFixedVariant: string;

    surface: string;
    onSurface: string;

    surfaceDim: string;
    surfaceBright: string;

    surfaceContainerLowest: string;
    surfaceContainerLow: string;
    surfaceContainer: string;
    surfaceContainerHigh: string;
    surfaceContainerHighest: string;

    surfaceVariant: string;
    onSurfaceVariant: string;

    outline: string;
    outlineVariant: string;

    inverseSurface: string;
    inverseOnSurface: string;
    inversePrimary: string;
    inverseOnPrimary: string;

    shadow: string;
    scrim: string;

    surfaceTintColor: string;

    background: string;
    onBackground: string;

    info: string;
    onInfo: string;
    infoContainer: string;
    onInfoContainer: string;

    success: string;
    onSuccess: string;
    successContainer: string;
    onSuccessContainer: string;

    warning: string;
    onWarning: string;
    warningContainer: string;
    onWarningContainer: string;
}

export const PrimaryDefault: Tone = {
    '0': "#000000",
    "4": "#00101b",
    "6": "#001522",
    "10": "#001e2e",
    "12": "#002234",
    "17": "#002d43",
    "20": "#00344c",
    "22": "#003952",
    "24": "#003d58",
    "30": "#004c6c",
    "40": "#00658e",
    "50": "#007fb2",
    "60": "#2f9ad0",
    "70": "#53b5ec",
    "80": "#84cfff",
    "87": "#b4e0ff",
    "90": "#c7e7ff",
    "92": "#d3ebff",
    "94": "#dff0ff",
    "95": "#e5f3ff",
    "96": "#eaf5ff",
    "98": "#f6faff",
    "99": "#fbfcff",
    "100": "#ffffff"
}

export const SecondaryDefalult: Tone = {
    "0": "#000000",
    "4": "#00101b",
    "6": "#031520",
    "10": "#0b1d29",
    "12": "#0f212d",
    "17": "#1a2c38",
    "20": "#21323e",
    "22": "#253743",
    "24": "#2a3b47",
    "30": "#374955",
    "40": "#4f616e",
    "50": "#677987",
    "60": "#8193a1",
    "70": "#9baebc",
    "80": "#b6c9d8",
    "87": "#cadcec",
    "90": "#d2e5f5",
    "92": "#d8ebfa",
    "94": "#dff0ff",
    "95": "#e5f3ff",
    "96": "#eaf5ff",
    "98": "#f6faff",
    "99": "#fbfcff",
    "100": "#ffffff"
}

export const TertiaryDefault: Tone = {
    "0": "#000000",
    "4": "#110827",
    "6": "#160e2c",
    "10": "#1e1635",
    "12": "#231a39",
    "17": "#2d2544",
    "20": "#342b4b",
    "22": "#383050",
    "24": "#3d3455",
    "30": "#4b4263",
    "40": "#63597c",
    "50": "#7c7296",
    "60": "#968bb1",
    "70": "#b1a5cc",
    "80": "#cdc0e9",
    "87": "#e0d4fd",
    "90": "#e8ddff",
    "92": "#eee4ff",
    "94": "#f3eaff",
    "95": "#f5eeff",
    "96": "#f8f1ff",
    "98": "#fdf7ff",
    "99": "#fffbff",
    "100": "#ffffff"
}
export const NeutralDefault: Tone = {
    "0": "#000000",
    "4": "#0c0e10",
    "6": "#111416",
    "10": "#191c1e",
    "12": "#1d2022",
    "17": "#282a2c",
    "20": "#2e3133",
    "22": "#333537",
    "24": "#37393c",
    "30": "#454749",
    "40": "#5c5e61",
    "50": "#75777a",
    "60": "#8f9193",
    "70": "#aaabae",
    "80": "#c5c6c9",
    "87": "#d9dadd",
    "90": "#e2e2e5",
    "92": "#e7e8eb",
    "94": "#edeef0",
    "95": "#f0f1f3",
    "96": "#f3f3f6",
    "98": "#f9f9fc",
    "99": "#fcfcff",
    "100": "#ffffff"
}

export const NeutralVariantDefault = {
    "0": "#000000",
    "4": "#080f14",
    "6": "#0e1419",
    "10": "#161c21",
    "12": "#1a2025",
    "17": "#242b30",
    "20": "#2b3136",
    "22": "#2f363b",
    "24": "#333a3f",
    "30": "#41484d",
    "40": "#595f65",
    "50": "#71787e",
    "60": "#8b9198",
    "70": "#a5acb2",
    "80": "#c1c7ce",
    "87": "#d4dbe1",
    "90": "#dde3ea",
    "92": "#e3e9f0",
    "94": "#e8eff5",
    "95": "#ebf1f8",
    "96": "#eef4fb",
    "98": "#f6faff",
    "99": "#fbfcff",
    "100": "#ffffff"
}
export const ErrorDefault = {
    "0": "#000000",
    "4": "#280001",
    "6": "#310001",
    "10": "#410002",
    "12": "#490002",
    "17": "#5c0004",
    "20": "#690005",
    "22": "#710005",
    "24": "#790006",
    "30": "#93000a",
    "40": "#ba1a1a",
    "50": "#de3730",
    "60": "#ff5449",
    "70": "#ff897d",
    "80": "#ffb4ab",
    "87": "#ffcfc9",
    "90": "#ffdad6",
    "92": "#ffe2de",
    "94": "#ffe9e6",
    "95": "#ffedea",
    "96": "#fff0ee",
    "98": "#fff8f7",
    "99": "#fffbff",
    "100": "#ffffff"
}

export const componentConfiguration = (themeTokens: ThemeTokens): UIComponentConf => {

    return    (
        {
            'background': {
                backgroundColor: themeTokens.surfaceContainer,
                color: themeTokens.onBackground
            },
            'popover': {
                backgroundColor: themeTokens.secondary,
                color: themeTokens.onSecondary,
                border: 'none'
            },
            'header': {
                width: '100%',
                display: 'flex',
                flexDirection: 'rows',
                paddingTop: '10px',
                marginRight: '20px',
                marginLeft: '10px',
                marginTop: '10px',
                marginBottom: '20px',
                color: themeTokens.onSurface,
            },
            'search': {
                display: 'flex',
                backgroundColor: 'white',
                gap: '10px',
                width: '100%',
                flexDirection: 'row',
                border: '1px solid lightgray',
                padding: '5px 10px 5px 10px',
                borderRadius: '20px',
                alignItems: 'center'
            },
            'input': {
                border: 'none',
                borderRadius: '20px',
                padding: '5px 10px 5px 10px',
                color: themeTokens.onSurface,
                backgroundColor: themeTokens.surface,

            },
            'form': {
                'error': {
                    color: themeTokens.error
                }
            },
            'logo': {
                color: themeTokens.primary,
                fill: themeTokens.onSurface,
                stroke: themeTokens.primary
            },
            'info': {
                display: 'flex',
                fontSize: '1.1rem',
                flexDirection: 'row',
                width: '100%',
                borderRadius: '10px',
                padding: '5px',
                backgroundColor: `${themeTokens.infoContainer}`,
                color: themeTokens.onInfoContainer,
                //border: '2px solid #adc2e1',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                marginBottom: '20px',
            },
            'error': {
                fontSize: '1.1rem',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                width: '100%',
                borderRadius: '10px',
                padding: '10px',
                border: 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                color: themeTokens.onError,
                marginBottom: '20px',
                backgroundColor: themeTokens.error,
                justifyItems: 'flex-start'
            },
            'tag': {
                'base': {
                    color: themeTokens.primary,
                    border: `1px solid ${themeTokens.primary}`,
                    backgroundColor: themeTokens.primaryContainer,
                    padding:'5px 10px 5px 10px',
                    borderRadius:'5px',
                },
                'info': {
                    color: themeTokens.primary,
                    border: `1px solid ${themeTokens.primary}`,
                    backgroundColor: themeTokens.primaryContainer,
                    padding:'5px 10px 5px 10px',
                    borderRadius:'5px',
                },
                'error': {
                    color: themeTokens.error,
                    border: `1px solid ${themeTokens.error}`,
                    backgroundColor: themeTokens.errorContainer,
                    padding:'5px 10px 5px 10px',
                    borderRadius:'5px',
                },
                'warning': {
                    color: themeTokens.warning,
                    border: `1px solid ${themeTokens.warningContainer}`,
                    backgroundColor: themeTokens.warning,
                    padding:'5px 10px 5px 10px',
                    borderRadius:'5px',
                },
                'success': {
                    color: themeTokens.success,
                    border: `1px solid ${themeTokens.success}`,
                    backgroundColor: themeTokens.successContainer,
                    padding:'5px 10px 5px 10px',
                    borderRadius:'5px',
                }
            },
            'table': {
                'paper': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '10px',
                    backgroundColor: themeTokens.surfaceContainerHighest,
                    color: themeTokens.onSurface,
                    gap: '20px'
                },
                'header': {
                    border: `none`,
                    borderRadius: '20px',
                    padding: '10px',
                    fontSize: '1rem',
                    fontWeight: '700',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surfaceDim,
                    marginBottom: '10px'

                },
                'row': {
                    borderColor: themeTokens.outline,
                    padding: '5px'
                },
                'cell': {
                  borderColor: themeTokens.outline,
                  paddingLeft: '10px'
                },
                'pagination': {
                    'paper': {
                        border: 'none',
                        borderRadius: '20px',
                        padding: '10px 20px 10px 20px',
                        fontSize: '1rem',
                        fontWeight: '400',
                        color: themeTokens.onPrimaryContainer,
                        backgroundColor: themeTokens.primaryContainer,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        gap: '10px'
                    },
                    'numOfElements': {
                        border: 'none',
                        borderRadius: '20px',
                        padding:'5px 10px 5px 10px',
                        borderRight: '5px solid transparent',
                        color:themeTokens.onSurface,
                        backgroundColor: themeTokens.surface,
                        WebkitAppearance: 'none',
                        width: '4rem',
                        appearance: 'none',
                        MozAppearance: 'none',
                        // background: `${themeTokens.surface} url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTYiIHdpZHRoPSIxMCIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjwhLS0hRm9udCBBd2Vzb21lIEZyZWUgNi41LjEgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UvZnJlZSBDb3B5cmlnaHQgMjAyNCBGb250aWNvbnMsIEluYy4tLT48cGF0aCBvcGFjaXR5PSIxIiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTM3LjQgNDEuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMGwxMjggMTI4YzkuMiA5LjIgMTEuOSAyMi45IDYuOSAzNC45cy0xNi42IDE5LjgtMjkuNiAxOS44SDMyYy0xMi45IDAtMjQuNi03LjgtMjkuNi0xOS44cy0yLjItMjUuNyA2LjktMzQuOWwxMjgtMTI4em0wIDQyOS4zbC0xMjgtMTI4Yy05LjItOS4yLTExLjktMjIuOS02LjktMzQuOXMxNi42LTE5LjggMjkuNi0xOS44SDI4OGMxMi45IDAgMjQuNiA3LjggMjkuNiAxOS44czIuMiAyNS43LTYuOSAzNC45bC0xMjggMTI4Yy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMHoiLz48L3N2Zz4K") no-repeat 100% 50%`
                    },
                    'elements': {
                        border: '',
                        borderRadius: '',
                        padding: '',
                        fontSize: '',
                        fontWeight: '',
                        color: '',
                        backgroundColor: '',
                    },
                    'navigation': {
                        border: 'none',
                        borderRadius: '20px',
                        padding: '',
                        fontSize: '',
                        fontWeight: '',
                        color: themeTokens.secondary,
                        backgroundColor: themeTokens.primary,
                    }
                }

            },
            'button': {
                border: 'none',
                borderRadius: '20px',
                padding: '5px 15px 5px 15px',
                fontSize: '1rem',
                fontWeight: '700',
                height:'2.5rem',
                color: themeTokens.onPrimary,
                backgroundColor: themeTokens.primary,
            },
            'link': {
                cursor: 'pointer',
                fontWeight: 700,
                textDecorationStyle: 'dashed',
                textUnderlineOffset: '5px',
                color: themeTokens.primary
            },
            'menu': {
                base: {
                    color: themeTokens.onBackground,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    height: '100vh',
                    justifyContent: 'flex-start'
                },
                hover: {
                    backgroundColor: themeTokens.secondaryContainer,
                    color: themeTokens.onBackground,
                    width: '100%',
                    borderRadius: '0px 20px 20px 0px'
                }
            },
            'paper': {
                'nested0': {
                    border: '',
                    borderRadius: '',
                    padding: '',
                    fontSize: '',
                    fontWeight: '',
                    color: '',
                    backgroundColor: '',
                },
                'nested1': {
                    border: '',
                    borderRadius: '',
                    padding: '',
                    fontSize: '',
                    fontWeight: '',
                    color: '',
                    backgroundColor: '',
                },
                'nested2': {
                    border: '',
                    borderRadius: '',
                    padding: '',
                    fontSize: '',
                    fontWeight: '',
                    color: '',
                    backgroundColor: '',
                }
            },
            'tooltip': {
                base: {
                    display: 'flex',
                    position: 'absolute',
                    borderRadius: '20px',
                    padding: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: themeTokens.info,
                    background: themeTokens.infoContainer,
                    fontWeight: 700,
                    lineHeight: 1.5,
                    zIndex: 100,
                    width: '25vw',
                    alignItems: 'center',
                    justifyContent: 'center',
                    wordBreak: 'keep-all',
                    whiteSpace: 'break-spaces',
                    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
                },
                right: {
                    left: 'calc(100% + var(--tooltip-margin))',
                    top: '50%',
                    transform: 'translateX(0) translateY(-50%)'
                },
                bottom: {
                    bottom: 'calc(var(--tooltip-margin) * -1)'
                },
                top: {
                    top: 'calc(var(--tooltip-margin) * -1)'
                },
                left: {
                    left: 'auto',
                    right: 'calc(100% + var(--tooltip-margin))',
                    top: '50%',
                    transform: 'translateX(0) translateY(-50%)'
                }
            },
            'typography': {
                title: {
                    fontSize: '1.5rem',
                    fontWeight: 700
                },
                label: {},

            },
            'titledPaper': {
                'none': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400'
                },
                'normal': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surface,
                },
                'lower': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surfaceContainerLow,
                },
                'lowest': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surfaceContainerLowest,
                },
                'high': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surfaceContainerHigh,
                },
                'highest': {
                    border: 'none',
                    borderRadius: '20px',
                    padding: '20px',
                    fontSize: '1rem',
                    fontWeight: '400',
                    color: themeTokens.onSurface,
                    backgroundColor: themeTokens.surfaceContainerHighest,
                }
            },
            'footer': {
                display: 'flex',
                width: '100%',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'center',
                gap:"20px",
                border: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                color: themeTokens.onSurface,
                marginBottom: '20px'
            }
        }
    )
}

export const DefaultTonalPalette: TonalPalette = {
    primary: PrimaryDefault,
    secondary: SecondaryDefalult,
    tertiary: TertiaryDefault,
    neutral: NeutralDefault,
    neutralVariant: NeutralVariantDefault,
    error: ErrorDefault
}

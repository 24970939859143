/** @jsxImportSource @emotion/react */
import React from 'react'
import {IconI} from "./types";
import {divCSS} from "@nic-react-ui/core";
import {defaultIconStyle, ratioXY} from "./index";
import {css, keyframes} from "@emotion/react";

const snow = keyframes({
    '0%': {opacity: 1},
    '100%': {opacity: 0}
});


const oddRain = css({
    animation: `${snow} 1.5s infinite linear`,
    animationDelay: '0.5s'
});

const evenRain = css({
    animation: `${snow} 1.5s infinite linear`,
    animationDelay: '1.5s'
})

interface RainLogoI extends IconI {
    animate?: boolean
}

export const RainLogo: React.FC<RainLogoI> = (props) => {
    const [ratioX, ratioY] = ratioXY(126.03256,93.411491, props.ratio??100)

    const animate = props.animate ?? false
    const oddCss = (animate ? oddRain: {})
    const evenCss = (animate ? evenRain: {})

    return (
        <div
            css={[divCSS(props), defaultIconStyle(ratioX, ratioY)]}
            onClick={props.onClick}
        >
            <svg
                width={ratioX}
                height={ratioY}
                viewBox="0 0 126.03256 93.411491"
                stroke={'currentColor'}
            >

                <g
                    transform="translate(-35.801977,-42.319021)">
                    <path
                        fill="none"
                        stroke={"currentColor"}
                        strokeWidth="0.457428"
                        strokeMiterlimit="10"
                        d="m 62.772401,133.42093 c -8.962619,2.1125 -22.335022,-8.49378 -24.447517,-17.4564 -2.112495,-8.96261 2.57922,-19.25958 11.541839,-21.37207 -5.28193,-22.40949 9.565323,-43.70268 31.974825,-48.98462 19.833592,-4.67479 40.668312,3.72552 48.634162,21.6954 l 3.92666,-0.92552 c 11.20672,-2.64143 22.77357,3.28563 25.41499,14.49234"
                        style={{
                            strokeWidth:5.03437, strokeLinecap:'round', strokeMiterlimit:10, strokeDasharray:'none'
                        }}
                    />

                    <g
                        css={oddCss}
                        transform="translate(20.682126,-120.39103)">

                        <g>

                            <path
                                d="M 136.75546,230.14569 118.5429,211.93313 c -2.30386,-2.22527 1.11315,-5.64228 3.33842,-3.33842 l 18.21256,18.21256 c 2.14937,2.22528 -1.11314,5.48779 -3.33842,3.33842 z"
                                style={{
                                    fill: 'currentColor',
                                    strokeWidth:1.79299,
                                    strokeMiterlimit:10,
                                    strokeDasharray:'none'
                                }}

                            />
                        </g>

                    </g>

                    <g
                        css={evenCss}
                        transform="translate(11.010694,-96.06979)">
                        <g>
                            <path
                                d="M 136.75546,230.14569 118.5429,211.93313 c -2.30386,-2.22527 1.11315,-5.64228 3.33842,-3.33842 l 18.21256,18.21256 c 2.14937,2.22528 -1.11314,5.48779 -3.33842,3.33842 z"
                                id="path900-5-0-7-3"
                                style={{
                                    fill: 'currentColor',
                                    strokeWidth:1.79299,
                                    strokeMiterlimit:10,
                                    strokeDasharray:'none'
                                }}

                            />
                        </g>

                    </g>

                    <g
                        css={oddCss}
                        transform="translate(-20.055814,-98.529208)">
                        <g>
                            <path
                                d="M 136.75546,230.14569 118.5429,211.93313 c -2.30386,-2.22527 1.11315,-5.64228 3.33842,-3.33842 l 18.21256,18.21256 c 2.14937,2.22528 -1.11314,5.48779 -3.33842,3.33842 z"
                                style={{
                                    fill: 'currentColor',
                                    strokeWidth:1.79299,
                                    strokeMiterlimit:10,
                                    strokeDasharray:'none'
                                }}

                            />
                        </g>

                    </g>

                    <g
                        css={evenCss}
                        transform="translate(-47.531185,-95.117842)">
                        <g>
                            <path
                                d="M 136.75546,230.14569 118.5429,211.93313 c -2.30386,-2.22527 1.11315,-5.64228 3.33842,-3.33842 l 18.21256,18.21256 c 2.14937,2.22528 -1.11314,5.48779 -3.33842,3.33842 z"
                                style={{
                                    fill: 'currentColor',
                                    strokeWidth:1.79299,
                                    strokeMiterlimit:10,
                                    strokeDasharray:'none'
                                }}

                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}
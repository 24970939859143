/** @jsxImportSource @emotion/react */
import React from "react";
import {IconI} from "./types";
import {ChevronUp} from "./ChevronUp";
import {ChevronDown} from "./ChevronDown";

interface OpenIconI extends IconI {
    open: boolean
}


export const OpenIcon: React.FC<OpenIconI> = (props) => {
    if (props.open) {
        return <ChevronUp {...props}/>
    } else {
        return <ChevronDown {...props}/>
    }
}
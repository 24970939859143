import React, {PropsWithChildren} from "react";
import Keycloak from "keycloak-js";
import {log, LogLevel} from "../../logger";
/*
interface AuthProviderConfigurationI {
    [paramName: string]: any
}
*/
export type AuthEvent = 'onReady'
    | 'onInitError'
    | 'onAuthSuccess'
    | 'onAuthError'
    | 'onAuthRefreshSuccess'
    | 'onAuthRefreshError'
    | 'onAuthLogout'
    | 'onTokenExpired'

interface AuthContextI {
    initialized: boolean
    authenticated: boolean
    authClient: any
}

const AuthContext = React.createContext<AuthContextI>({
    initialized: false,
    authenticated: false,
    authClient: {}
});

export interface TokensI {
    token?: string
    refreshToken?: string
    idToken?: string
}

interface AuthProviderI extends PropsWithChildren {
    authClient: Keycloak
    scope?: string
    loadingComponent?: React.ReactNode
    autoRefreshToken?: boolean
    onEvent: (event: AuthEvent, keycloak: Keycloak) => void
    onError?: (errorEvt: string, error?: AuthError) => void
    onToken?: (tokens: TokensI) => void
}

export interface AuthError {
    error: string
    error_description: string
}
/*
const defaultInitOptions = {
    onLoad: 'check-sso' as KeycloakOnLoad
}
*/
export const AuthProvider: React.FC<AuthProviderI> = (props) => {
    const {authClient, scope} = props;
    const [initialized, setInitialized] = React.useState(false);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const checkStatus = (authClient: Keycloak) => {
        const isLoadingC = false;
        const isAuthenticatedC = isUserAuthenticated(authClient)
        log(LogLevel.info, `CheckStatus: (isAutenticatedC, isAuthenticated, isLoadingC, isLoading, initialized)= ${isAuthenticatedC}, ${isAuthenticated}, ${isLoadingC}, ${isLoading}, ${initialized}`)
        if (
            (isLoadingC !== isLoading) ||
            (isAuthenticatedC !== isAuthenticated) ||
            (!initialized)) {
            setInitialized(true);
            setIsLoading(isLoadingC);
            setIsAuthenticated(isAuthenticatedC)
        }
    }


    React.useEffect(()=>{
        log(LogLevel.info, `AuthClient: ${authClient}, ${authClient.authenticated}`)
        if (authClient.authenticated === undefined) {
            (async () => {
                await initKeycloak();
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authClient.authenticated])

    const onAuthLogout = () => {

        const { idToken, refreshToken, token } = authClient
        checkStatus(authClient);
        props.onEvent && props.onEvent("onAuthLogout", authClient);

        props.onToken && props.onToken({
            token: token,
            idToken: idToken,
            refreshToken: refreshToken
        })

    }

    const onAuthSuccess = () => {
        const { idToken, refreshToken, token } = authClient;
        checkStatus(authClient);
        props.onEvent && props.onEvent("onAuthSuccess", authClient);
        props.onToken && props.onToken({
            token: token,
            idToken: idToken,
            refreshToken: refreshToken
        })
    }

    const onAuthRefreshSuccess = () => {

        checkStatus(authClient);
        props.onEvent && props.onEvent("onAuthRefreshSuccess", authClient);
        const { idToken, refreshToken, token } = authClient
        props.onToken && props.onToken({
            token: token,
            idToken: idToken,
            refreshToken: refreshToken
        })

    }
/*
    const onAuthRefreshError = () => {
        props.onError && props.onError("onAuthRefreshError");
    }
*/

    const onReady = (authenticated?: boolean) => {
        checkStatus(authClient);
        props.onEvent && props.onEvent('onReady', authClient)
    }


    const isUserAuthenticated = (authClient: any) => {
        return !!authClient.idToken && !!authClient.token
    }

/*
    const actionUpdateHandler = (action: string) => (status: 'success'|'cancelled'|'error') => {
        props.onEvent && props.onEvent(action as AuthEvent, authClient)
    }
*/
    const tokenExpiredHandler = () => {
        props.onEvent && props.onEvent('onTokenExpired', authClient)
        if (authClient.authenticated) {
            authClient.updateToken(5);
        }
    }

    const onError = (errorEvt: string) => (error?: AuthError) => {
        props.onError && props.onError(errorEvt, error);
    }

    const  initKeycloak =  async () => {
        const {authClient} = props;
        authClient.onAuthError = onError('authError');
        authClient.onAuthLogout = onAuthLogout;
        authClient.onAuthSuccess = onAuthSuccess;
        authClient.onReady = onReady;
        authClient.onAuthRefreshSuccess = onAuthRefreshSuccess;
        authClient.onAuthRefreshError = onError('refreshErrorEvt');
        //keycloak.onActionUpdate = actionUpdateHandler('actionUpdateEvt');
        authClient.onTokenExpired = tokenExpiredHandler;
        await authClient.init({onLoad: 'check-sso', checkLoginIframe: false, scope: scope});
  //      console.log('Authenticated: ', authenticated)
        //setInitialized(true);
    }

    const component = ( props.loadingComponent && (!initialized || isLoading) ? <>{props.loadingComponent}</> : (
        <AuthContext.Provider value={{
            initialized: initialized,
            authenticated: isAuthenticated,
            authClient: authClient
        }}>
            {props.children}
        </AuthContext.Provider>
    ));


    return (
        <>{component}</>
    )
}

export const useKeycloak = () => {
    const ctx = React.useContext(AuthContext);
    return {initialized: ctx.initialized, keycloak: ctx.authClient, login: ctx.authClient.login, authenticated: ctx.authenticated}
}

/**
 * Wrapper generico per proteggere la componente, controlla se keycloak è inizializzato e
 * @param component
 */
export const useProtectedComponent = (component: React.ReactElement): React.ReactElement => {
    const {initialized, keycloak, authenticated} = useKeycloak();
    React.useEffect(() => {

        if (initialized  && !authenticated) {
            keycloak.login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized])

    return (
        <>
        {keycloak.authenticated && component}
        </>
    )

}